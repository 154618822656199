import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { ClarityService } from 'src/app/shared/clarity/clarity.service';
import { AmeiCode } from '../../shared/models/amei-code.model';
import { environment } from './../../../environments/environment';
import { AmeiConfig } from './../../shared/models/amei-config.model';
import { AmeiToken } from './../../shared/models/amei-token.model';
import { UserAccess } from './../../shared/models/user-access.model';
import { User } from './../../shared/models/user.model';
import { RefreshTokenService } from './../refresh-token/refresh-token.service';
import { UserAccessService } from './../user-access/user-access.service';
import { UserService } from './../user/user.service';
import { UtilService } from './../util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthBaseService {
  isLoadingConfig: BehaviorSubject<boolean> = new BehaviorSubject(true);

  config = {
    client_id: window['env']?.client_id,
    redirect_uri: environment.redirect_uri,
    response_type: "code",
    scope: "openid"
  }

  private ameiConfigSubject: BehaviorSubject<AmeiConfig> = new BehaviorSubject(new AmeiConfig());
  ameiConfig: AmeiConfig = new AmeiConfig();

  private ameiTokenSubject: BehaviorSubject<AmeiToken> = new BehaviorSubject(new AmeiToken());
  ameiTokenInterceptor: AmeiToken = new AmeiToken();
  private loggedUserSubject: BehaviorSubject<{ user: User, userAccess: UserAccess[] }> = new BehaviorSubject({ user: new User(), userAccess: [] });

  userDataSubscription: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private refreshTokenService: RefreshTokenService,
    private utilService: UtilService,
    private userService: UserService,
    private userAccessService: UserAccessService,
    private router: Router,
  ) {
  }

  getEnvironmentVariables(str: string): Observable<any> {
    return this.http.get<any>(str + 'public/endpoints');
  }

  setEnvironmentVariables(amei_login: string, realm: string, resource: string, ameiMinhaConta: string, clarityId: string): void {
    window['env'] = {
      amei_login: amei_login,
      client_id: resource,
      realm: realm,
      ameiMinhaConta: ameiMinhaConta,
      clarity_id: clarityId
    }

    this.config = {
      client_id: window['env'].client_id,
      redirect_uri: environment.redirect_uri,
      response_type: "code",
      scope: "openid"
    }
    ClarityService.loadClarity(window['env'].clarity_id);
  }

  getAmeiConfig(): Observable<AmeiConfig> {
    return this.ameiConfigSubject;
  }

  setAmeiConfig(): void {

    this.http.get<AmeiConfig>(window['env'].amei_login).subscribe((res: AmeiConfig) => {
      this.ameiConfig = res;
      this.ameiConfigSubject.next(res);
    })
  }

  setAmeiToken(isRefreshToken: boolean = false): void {

    this.getAmeiConfig().pipe(debounceTime(200)).subscribe((res: AmeiConfig) => {

      if (res.token_endpoint) {

        let ameiCode: AmeiCode = new AmeiCode;
        if (!isRefreshToken) {
          ameiCode = this.utilService.queryStringToParams(window.location.search.substring(1)) as AmeiCode;
        }

        let obj: AmeiCode = new AmeiCode(
          {
            code: !isRefreshToken ? ameiCode.code : null,
            refresh_token: isRefreshToken ? this.refreshTokenService.getCookie('rt') : null,
            // session_state: ameiCode.session_state,
            grant_type: !isRefreshToken ? 'authorization_code' : 'refresh_token',
            client_id: window['env'].client_id,
            redirect_uri: this.config.redirect_uri
          }
        )

        this.http.post<AmeiToken>(res.token_endpoint, this.utilService.paramsToQueryString(obj, true), { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) }).subscribe((res: AmeiToken) => {          
          this.setLocalStorageAmeiToken(res);
          this.ameiTokenSubject.next(res);

          this.refreshTokenService.setCookie('rt', res.refresh_token, res.refresh_expires_in);
          this.setUserData();

          if (!isRefreshToken) {
            this.router.navigate(['/projects']);
          }
          

        }, (error) => {})
      }

    })

  }

  getAmeiToken(): Observable<AmeiToken> {
    return this.ameiTokenSubject;
  }

  setLocalStorageAmeiToken(ameiToken: AmeiToken): void {
    localStorage.setItem('amei-token', ameiToken.access_token);
  }

  getLocalStorageAmeiToken(): string | null {
    return localStorage.getItem('amei-token');
  }

  clearLocalStorageAmeiToken(): void {
    localStorage.removeItem('amei-token');
  }

  setLoggedUser(user: User, userAccess: UserAccess[]): void {
    let obj = {
      user: user,
      userAccess: userAccess
    }

    this.loggedUserSubject.next(obj);
  }

  getLoggedUser(): Observable<{ user: User, userAccess: UserAccess[] }> {
    return this.loggedUserSubject;
  }

  setUserData(): void {

    if (this.getLocalStorageAmeiToken()) {

      this.getAmeiToken().pipe(take(1)).subscribe((res: AmeiToken) => {

        const userData = this.userService.getAll(null, 'meus-dados');
        const userPermission = this.userAccessService.getAll(undefined, 'meus-acessos');

        this.userDataSubscription.unsubscribe();
        this.userDataSubscription = forkJoin([userData, userPermission]).subscribe((res: [User, UserAccess[]]) => {
          this.setLoggedUser(res[0], res[1]);
        })
      })
    }
  }

  logout(): void {
    this.clearLocalStorageAmeiToken();
    this.refreshTokenService.setCookie('rt', 0, 0);

    this.router.navigate(['/']);

    window.location.href = this.ameiConfig.end_session_endpoint + this.utilService.paramsToQueryString({ post_logout_redirect_uri: this.config.redirect_uri });
  }

  getAmeiMinhaContaUrl(){
    return window['env']?.ameiMinhaConta;
  }

  getClarityId(){
    return window['env']?.clarity_id;
  }


}
